a {
  color: #000;
  text-decoration: none;
}

a:hover {
  color: #ff9406;
  text-decoration: none;
}

html, body {
  overflow-x: hidden;
  width: 100%;
}

.cart-button {
  margin: 2rem auto;
  padding: 5px .625rem;
}

.cart-title {
  font-size: 2rem;
  font-family: 'Bangers', cursive;
}

.count-cart {
  padding: 0 5px;
}

.dark-gray-bg {
  background-color: #333;
}

.gray-bg {
  background-color: #cfcfcf;
}

.offwhite-bg {
  background-color: #acacac;
}

.green-button {
  color: #fff;
  background-image: none;
  background-color: #29c60e;
  border-color: #29c60e;
}

.green-button:hover {
  color: #29c60e;
  background-color: #fff;
  border-color: #29c60e;
  transition-duration: .8s;
}

.hr {
  height: 1px;
  background-color: #fafafa;
  width: 80%;
}

.lg-button {
  border-radius: 180px;
  box-shadow: 2px 2px 0 0 #000;
  font-family: 'Average Sans', sans-serif;
  font-size: 1.25rem;
  padding: .625rem 1.25rem;
  margin: 1.25rem auto 2rem;
}

.lg-margin {
  margin: .625rem 50px;
}

.lg-padding {
  padding: 5px 2rem;
}

.xl-padding {
  padding: 20px 250px;
}

@media (max-width:1199px) {
  .xl-padding {
    padding: 18px 150px;
  }
}

@media (max-width:991px) {
  .xl-padding {
    padding: 15px 100px;
  }
}

@media (max-width:767px) {
  .xl-padding {
    padding: 12px 50px;
  }
}

@media (max-width:575px) {
  .xl-padding {
    padding: 5px 25px;
  }
}

.lg-paragraph {
  color: #000;
  font-family: 'Average Sans', sans-serif;
  font-size: .875rem;
}

.link-heading {
  font-family: 'Bangers', cursive;
  padding: 2rem 5px;
  width: 80%;
}

.link-icon {
  vertical-align: middle;
}

.links-div {
  background-color: #f6f6f6;
  margin: 2rem auto 0;
}

.logo-tile {
  background-color: transparent;
  height: 150px;
  margin: .625rem auto 50px;
  width: 150px;
}

@media (max-width:575px) {
  .logo-tile {
    margin: 0 auto;
  }
}

.main-heading {
  color: #030eff;
  font-size: 30px;
  font-weight: 700;
}

.md-button {
  border-radius: 180px;
  box-shadow: 2px 2px 0 0 #000;
  font-family: 'Average Sans', sans-serif;
  font-size: .75rem;
  margin: .625rem auto;
  padding: .625rem 1.5rem;
}

.md-margin {
  margin: 5px 1.5rem;
}

.md-padding {
  padding: 2px 1rem;
}

.md-paragraph {
  color: #000;
  font-family: 'Average Sans', sans-serif;
  font-size: .75rem;
}

.orange-button {
  color: #fff;
  background-color: #ff9406;
  background-image: none;
  border-color: #ff9406;
}

.orange-button:hover {
  color: #ff9406;
  background-color: #fff;
  border-color: #ff9406;
  transition-duration: .8s;
}

.padding-20 {
  padding: 1.25rem;
}

.product-link {
  background-color: transparent;
  box-shadow: 2px 2px .625rem #888;
  height: 150px;
  margin: .625rem auto 50px;
  width: 150px;
}

.product-link:hover {
  box-shadow: 2px 2px 1.25rem #555;
  cursor: pointer;
}

.sm-margin {
  margin: 2px .75rem;
}

.sm-padding {
  padding: 1px 7px;
}

.sm-paragraph {
  color: #000;
  font-family: 'Average Sans', sans-serif;
  font-size: .625rem;
}

.social-div {
  padding: 2rem 5px;
}

.social-link-icon {
  border-radius: 6px;
  width: 80px;
  margin: 0 .625rem;
  box-shadow: 2px 2px .625rem #888;
}

.social-link-icon:hover {
  box-shadow: 4px 4px .75rem #666;
}

.sub-heading {
  color: #000;
  font-family: 'Bangers', cursive;
  font-size: 1.5rem;
  font-weight: 500;
  margin: 1.25rem auto .625rem;
}

.xlg-paragraph {
  font-family: 'Average Sans', sans-serif;
  font-size: 1.125rem;
}

@media (max-width:575px) {
  .cart-title {
    font-size: 1.5rem;
  }
  .lg-button {
    font-size: 20px;
  }
  .main-heading {
    margin: .625rem;
    padding: 5px 1.25rem 0;
    text-align: center;
  }
}

.montserrat-font {
  font-family: 'Montserrat', sans-serif;
}

.dancing-font {
  font-family: 'Dancing Script', cursive;
}

.orange-text {
  color: #ff9406;
}

.white-text {
  color: #fff;
}

.skill-tag {
  background-color: #d6d6d6;
  border-radius: 20px;
  margin: 5px;
  padding: 5px 15px;
}

.profile-img {
  border-radius: 50%;
  padding: 10%;
  width: 100%;
}

.circle-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 125px;
  width: 125px;
  border-radius: 50%;
  background-color: #ff9406;
}

.circle-icon {
  color: #fff;
  font-size: 75px;
}

.fa-icon {
  padding: 0 0 0 3px;
}

.font-blue {
  color: #030eff;
}